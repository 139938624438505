import { Ionicons } from "@expo/vector-icons";
import { Button, Card, CardContent, Icon, InputLabel } from "@material-ui/core";
import moment from "moment";
import React from "react";
import {
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native-web";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import Colors from "../../commons/Colors";
import { DeleteHandler, PostHandler } from "../../handlers";

class ProfissionalItems extends React.Component {
  componentDidMount() {
  }
  render() {
    /*const today = moment();
    const created = moment(this.props.data.dataCad ?? undefined);

    const dateData = {
      years: Math.abs((parseInt(today.format('yyyy')) - parseInt(created.format('yyyy')))),
      months: Math.abs((parseInt(today.format('MM')) - parseInt(created.format('MM')))),
      days: Math.abs(parseInt(moment().format('DD')) - parseInt(moment(moment().subtract(1,'month')).endOf('month').format('MM')))
    }*/

    return (
      <View style={{ marginBottom: 10 }}>
        <Card>
          <CardContent>
            <View style={{ flexDirection: "row" }}>
              <View>
                <Avatar
                  alt="Remy Sharp"
                  src={this.props.data?.urlFoto}
                  sx={{ width: 56, height: 56 }}
                />
              </View>
              <InputLabel style={{ fontSize: 18, color: Colors.secondary }}>
                {this.props.data?.nome}
              </InputLabel>
            </View>
          </CardContent>
        </Card>
      </View>
    );

    return (
      <View
        key={this.props?.key}
        style={{ width: Dimensions.get("screen").width }}
      >
        <Card>
          <CardContent
            style={{
              backgroundColor: "#e7e7e7",
              borderRadius: 15,
              margin: 15,
            }}
          >
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <View>
                  <img src={this.props.data?.urlFoto} />
                </View>
                <View style={{ marginStart: 15 }}>
                  <InputLabel style={{ fontSize: 18, color: Colors.secondary }}>
                    {this.props.data?.nome}
                  </InputLabel>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: 210,
                    }}
                  >
                    <View>
                      <InputLabel>
                        {this.props.data?.conselho?.codigo
                          ? this.props?.data?.conselho?.codigo + ":"
                          : ""}{" "}
                        {this.props?.data?.conselho?.codCBO ?? ""}
                      </InputLabel>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      {[
                        ...Array(
                          Number.parseInt(this.props.data.pontuacao ?? 0)
                        ),
                      ].map((item, key) => {
                        return (
                          <View>
                            <Icon name="star" />
                          </View>
                        );
                      })}
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: 290,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "baseline",
                      }}
                    >
                      <InputLabel
                        style={{
                          color: Colors.secondary,
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        R${" "}
                        {(this.props.data?.precoConsulta ?? "0.00").replace(
                          ".",
                          ","
                        )}
                      </InputLabel>
                      <InputLabel
                        style={{ color: Colors.secondary, fontSize: 11 }}
                      >
                         Por seção
                      </InputLabel>
                    </View>
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                        onPress={() => {
                          console.log(
                            "FAVORITO: ",
                            this.props?.profile,
                            `pacientes/${this.props?.profile?.id}/favoritos?perfilId=${this.props.profile.perfilId}`
                          );
                          if (this.props.data?.favoritoPaciente) {
                            DeleteHandler(
                              `pacientes/${this.props?.profile?.id}/favoritos}`,
                              { perfilId: this.props?.profile?.perfilId }
                            );
                          } else {
                            PostHandler(
                              `pacientes/${this.props.profile?.id}/favoritos?perfilId=${this.props.profile.perfilId}`,
                              { idDoutor: this.props.data.id }
                            );
                          }
                        }}
                      >
                        <View>
                          {/*console.log('DOUTOR: ', this.props.data?.favoritoPaciente)*/}
                          <Ionicons
                            color={Colors.secondary}
                            name={
                              this.props.data?.favoritoPaciente
                                ? "bookmark"
                                : "bookmark-outline"
                            }
                          />
                        </View>
                        <View>
                          <InputLabel style={{ color: Colors.secondary }}>
                            Favorito
                          </InputLabel>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View></View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ marginTop: 15, marginBottom: 10 }}>
              <InputLabel>{this.props.data?.sobre ? "Sobre" : ""}</InputLabel>
              <InputLabel>{this.props.data?.sobre}</InputLabel>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: 260,
              }}
            >
              <View>
                <InputLabel style={{ color: Colors.secondary }}>
                  {dateData.years ?? 0} Anos, {dateData.months ?? 0} Meses
                </InputLabel>
              </View>
              <View>
                <InputLabel style={{ color: Colors.secondary }}>
                  {this.props.data.totalConsultasAtendidas ?? 0} Consultas
                </InputLabel>
              </View>
              <View></View>
            </View>
            <View style={{ marginTop: 25, marginBottom: 5 }}>
              <Button
                mode="contained"
                color={Colors.secondary}
                onClick={() => {
                  //console.log('PERFIL DATA', this.props.data);
                  //this.props.setProfileData(this.props.data);
                }}
              >
                Ver Perfil
              </Button>
            </View>
          </CardContent>
        </Card>
      </View>
    );
  }
}

export default ProfissionalItems;
