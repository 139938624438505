import React, { Component, Fragment } from "react";
import { View, ScrollView } from "react-native-web";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//
import Colors from "../../commons/Colors";
//
import {
  setSnackbar,
  setProfileDialog,
  setTimer,
} from "../../actions/appActions";
import { toMoney } from "../../handlers/money";
import { DeleteHandler, GetHandler, PostHandler } from "../../handlers";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import {
  ArrowLeft,
  ArrowLeftSharp,
  BookmarkBorderOutlined,
  BookmarkSharp,
  CheckBox,
  Star,
} from "@material-ui/icons";
import ProfissionalItems from "./profissionalItems";
import moment from "moment";
import { Alert, Dimensions, TouchableOpacity } from "react-native";
import { Link } from "react-router-dom";
import CalendarHeader from "../Search/Profile/calendarHeader";
import Calendar from "../Search/Profile/calendar";
import Storage from "../../handlers/storage";
import { BRANDING } from "../../../branding";

function CalendarioCompleto(props) {
  const info = localStorage.getItem("user_info") ? JSON.parse(localStorage.getItem("user_info") ?? "{}") : {};

  const [tipo, setTipo] = React.useState(
    props.dados.procedimentoPadraoVideo?.valor !== undefined ? "video" : "presencial"
  );
  const [tipoDisabled, setTipoDisabled] = React.useState((props.dados.procedimentoPadrao?.valor == undefined || props.dados.procedimentoPadraoVideo?.valor == undefined));
  const [reloading, setReloading] = React.useState(false);
  const [date, setDate] = React.useState(moment());
  const [schedules, setSchedules] = React.useState([]);
  const [dependents, setDependents] = React.useState([]);
  const [pacientId, setpacientId] = React.useState(info?.id);

  const getDependents = async () => {
    const request = await GetHandler(
      `pacientes/${info?.id}/dependentes`, {
      perfilId: BRANDING?.perfilId,
      somenteAprovados:true
    }
    );

    if (
      request &&
      Array.isArray(request?.data?.data) &&
      request?.data?.data?.length >= 0
    ) {
      let dependents = [];
      dependents = [...request?.data?.data];
      setDependents(dependents);

    }
  }

  const getSchedules = async (data, first = false) => {
    setReloading(true);
    const response = await GetHandler(
      `agenda/listaHorarios/${props.dados?.id ?? props.dados?.doutorId}`,
      { data, perfilId: props.dados?.perfilId },
      props?.navigation
    );
    const schedules = response?.data ?? [];

    setSchedules(schedules);

    setReloading(false);

    return schedules;
  };
  React.useEffect(() => {
    async function fetchData() {
      await getSchedules(date.format("yyyy-MM-DD"));
      await getDependents();
    }
    fetchData();
  }, []);

  return (
    <View>
      <CalendarHeader
        dataStart={date}
        getData={(dataStart) => { }}
        setDate={async (dataStart) => {
          props.setDate(dataStart);
          setDate(dataStart);
          //const scheduleData = date
          //scheduleData.data = dataStart.format("yyyy-MM-DD");
          await getSchedules(dataStart.format("yyyy-MM-DD"));
        }}
        month={true}
      />
      <ScrollView style={{ maxHeight: 250 }}>
        <Calendar
          refreshing={reloading}
          doutor={props.dados}
          date={date}
          data={schedules}
          type={tipo}
          setTime={(time) => {
            //const scheduleData = this.state.scheduleData;
            //scheduleData.horario = time;
            props.setTime({ time, tipo });
            // console.log(scheduleData);
          }}
        />
      </ScrollView>
      {schedules[0]?.dataDisponivel && (
        <View>
          <FormControl style={{ marginTop: 10 }}>
            <FormLabel id="radio-group-label">Tipo de atendimento</FormLabel>
            <RadioGroup
              style={{ flexDirection: "row", flex: 1 }}
              disabled={tipoDisabled}
              aria-labelledby="radio-group"
              name="radio-group"
              value={
                tipo
              }
              onChange={(event) => {
                setTipo(event.target.value);
                props.setTime({ tipo: event.target.value });
              }}
            >
              <FormControlLabel
                value="video"
                control={
                  <Radio
                    color={"primary"}
                    disabled={tipoDisabled}
                  />
                }
                label="Online"
              />
              <FormControlLabel
                value="presencial"
                control={<Radio disabled={tipoDisabled} color={"primary"} />}
                label="Presencial"
              />
            </RadioGroup>
          </FormControl>

          {dependents.length > 0 && (<View
            style={{
              width: "100%",
              marginEnd: 15,
              marginTop: window.innerWidth >= 680 ? undefined : 10,
            }}
          >
            <InputLabel style={{ fontSize: 12, marginBottom: 5 }} id="especialidade-select-label">
              Para quem é a consulta ?
            </InputLabel>
            <FormControl sx={{ m: 1 }} size="small">

              <Select
                size={'small'}
                labelId="dependents-select-label"
                id="dependents-select"
                value={
                  (dependents ?? []).find(
                    (item) => item.pacienteId == pacientId
                  )?.pacienteId ?? info?.id
                }
                label="Dependentes"
                onChange={(e) => {
                  setpacientId(e.target.value);
                }}
              >
                <MenuItem key={`especialidade-${info?.pacienteId}`} value={info?.id}>
                  {`${info?.nome} ${info?.sobrenome}(Você)`}
                </MenuItem>
                {(dependents ?? []).map((item) => (
                  <MenuItem
                    key={`especialidade-${item?.pacienteId}`}
                    value={item?.pacienteId}
                  >
                    {item?.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </View>)}

          <Button
            fullWidth
            color={"primary"}
            variant={"contained"}
            style={{ marginTop: 15 }}
            onClick={async () => {
              await props.sendSchedule({ pacienteId: pacientId });
            }}
          >
            Agendar
          </Button>
        </View>
      )}
    </View>
  );
}

class SearchList extends Component {
  state = {
    dataStart: moment(),
    profile: undefined,
    doctorData: undefined,
    showOrderpanel: false,
    scheduleData: {
      pacienteId: undefined,
      perfilId: undefined,
      doutorId: this.props.dados?.id,
      horario: undefined,
      data: moment().format("yyyy-MM-DD"),
      tipoAtendimento: this.props?.location?.tipoAtendimento ?? "video",
      //linkPagSeguro: true,
      linkAsaas: true,
      formaPagAsaas: "",
    },
    orderBy: [
      { column: "pontuacao.desc", label: "Avaliação", checked: false },
      {
        column: "totalConsultasAtendidas.desc",
        label: "Número de consultas",
        checked: false,
      },
      { column: "precoConsulta.desc", label: "Maior Preço", checked: false },
      { column: "precoConsulta.asc", label: "Menor Preço", checked: false },
      { column: "pontuacao.desc", label: "Tempo de trabalho", checked: false },
    ],
    results: [],
    pageOptions: undefined,
    refreshing: false,
  };

  async setOrder(item) {
    let orderBy = this.state.orderBy;
    orderBy.find((order) => order.label == item.label).checked = !item.checked;
    this.setState({
      orderBy,
      showOrderpanel: false,
    });
    await this.props.getData(undefined, orderBy);
  }

  sendSchedule = async (item) => {
    let authData = JSON.parse(localStorage.getItem("user_info"));

    let scheduleData = this.state.scheduleData;
    scheduleData.formaPagAsaas = moment(scheduleData?.data).diff(moment(), 'days') > 4 ? '' : 'pix';
    scheduleData.doutorId = item?.id ?? item?.doutorId;
    scheduleData.perfilId = item?.perfilId;
    scheduleData.pacienteId = item?.pacienteId ?? scheduleData?.pacienteId ?? authData?.id;
    scheduleData.paciente = {
      nome: authData?.nome ? `${authData?.nome} ${authData?.sobrenome}` : "",
      cpf: authData?.cpf ?? "",
      email: authData?.email ?? "",
    };

    this.setState({ ...this.state, reloading: true });
    if (scheduleData.horario === undefined) {
      this.props.setProfileDialog({
        title: "Selecione um horário",
        body: "\n\nÉ necessário escolher um horário disponível para agendar a consulta",
        open: true,
        singleButton: true,
        type: "yesno",
        onSave: () => { },
        onCancel: () => { },
      });

      this.setState({ ...this.state, reloading: false });
      return false;
    }

    this.setState({ ...this.state, reloading: true });
    console.log("SHC: ", scheduleData);
    if (
      !scheduleData.pacienteId ||
      !scheduleData.perfilId ||
      !scheduleData.doutorId
    ) {
      this.props.setProfileDialog({
        title: "Login Obrigatório",
        body: "\n\nFaça login para continuar",
        open: true,
        singleButton: true,
        type: "yesno",
        onSave: () => {
          this.props.history.push({
            pathname: "/login",
            state: {
              data: scheduleData,
            },
          });
        },
        onCancel: () => { },
      });
      /*this.props.navigation.navigate("profile", {
        refresh: true,
        schedule: scheduleData,
      });*/
    } else {
      if ((authData?.cpf ?? "").length <= 10) {
        this.props.setProfileDialog({ open: true, type: "profile" });
        return false;
      }
      const scheduling = await PostHandler(
        `consultas`,
        scheduleData,
        undefined
      );

      if (scheduling?.data?.success) {
        this.props.setProfileDialog({
          title: "Atenção",
          body: "O seu horário foi reservado, e será confirmado após efetuar o pagamento !\nDeseja efetuar o pagamento da consulta agora?",
          open: true,
          //singleButton: true,
          type: "yesno",
          onSave: () => {
            window
              .open(
                scheduling?.data?.data?.linkCobranca?.linkCobranca,
                "_blank"
              )
              .focus();
            window.location.href = "/history";
          },
          onCancel: () => {
            window.location.href = "/history";
          },
        });
        /*if (
          confirm(
            "Efetuar Pagamento\n\nDeseja efetuar o pagamento da consulta agora?"
          ) == true
        ) {
          window
            .open(scheduling?.data?.data?.linkCobranca?.linkCobranca, "_blank")
            .focus();
          return false;
        }
        window.location.href = "/history";*/
      } else {
        //console.log('AGAGAG:', scheduling)
        this.props.setProfileDialog({
          title: "Problema ao agendar ",
          body:
            scheduling?.data?.message ??
            "Houve um problema ao tentar efetuar o agendamento",
          open: true,
          singleButton: true,
          type: "yesno",
          onSave: () => {
            window
              .open(
                scheduling?.data?.data?.linkCobranca?.linkCobranca,
                "_blank"
              )
              .focus();
            window.location.href = "/history";
          },
          onCancel: () => {
            window.location.href = "/history";
          },
        });
        /*alert(
          "Problema ao agendar " +
            (scheduling?.data?.message ??
              "Houve um problema ao tentar efetuar o agendamento")
        );*/
      }
    }
    this.setState({ ...this.state, reloading: false });
  };

  render() {
    const status = [
      {
        cod: "noPay",
        color: "#d26900",
        name: "Pendente",
      },
      {
        cod: "naoConfirmado",
        name: "Não Confirmado",
        icon: "calendar-blank",
        color: "#987b01",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "confirmado",
        name: "Confirmado",
        icon: "calendar-check",
        color: "#04bc17",
        background: "#d6fcd9",
        material: true,
      },
      {
        cod: null,
        name: "Agendado",
        icon: "calendar-blank",
        color: "#04bc17",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "jaSeEncontra",
        name: "Aguardando",
        icon: "timer",
        color: "#008085",
        background: "#d1f8f5",
      },
      {
        cod: "estaSendoAtendido",
        name: "Em Atendimento",
        icon: "person",
        color: "#2558c9",
        background: "#d1ddf8",
      },
      {
        cod: "jaFoiAtendido",
        name: "Atendido",
        icon: "checkmark-circle-outline",
        color: "#8b8b8b",
        background: "#efefef",
      },
      {
        cod: "faltou",
        name: "Não Compareceu",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
      {
        cod: "desmarcado",
        name: "Desmarcado",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
    ];
    return (
      <View>
        <View
          style={{
            //backgroundColor: Colors.primary,
            padding: 15,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View>
            <InputLabel style={{ color: Colors.primary }}>
              Meus Favoritos
            </InputLabel>
          </View>
          <View>
            <IconButton
              onClick={() => {
                window.location.href = "/";
              }}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <ArrowLeftSharp />
            </IconButton>
          </View>
        </View>

        {(this.props.data ?? []).length > 0 ? (
          <View style={{ marginBottom: 60, zIndex: 889 }}>
            <ScrollView>
              {(this.props.data ?? []).map((item, key) => {
                const today = moment();
                const created = moment(item.dataCad ?? undefined);

                const dateData = {
                  years: Math.abs(
                    parseInt(today.format("yyyy")) -
                    parseInt(created.format("yyyy"))
                  ),
                  months: Math.abs(
                    parseInt(today.format("MM")) -
                    parseInt(created.format("MM"))
                  ),
                  days: Math.abs(
                    parseInt(moment().format("DD")) -
                    parseInt(
                      moment(moment().subtract(1, "month"))
                        .endOf("month")
                        .format("MM")
                    )
                  ),
                };
                return (
                  <ItemList
                    item={item}
                    state={this.state}
                    sendSchedule={async (data = {}) => {
                      this.sendSchedule({ ...item, ...data });
                    }}
                    setDate={(data) => {
                      const scheduleData = this.state.scheduleData;
                      scheduleData.data = data.format("yyyy-MM-DD");
                      this.setState({ scheduleData });
                    }}
                    setTime={({ time, tipo }) => {
                      const scheduleData = this.state.scheduleData;
                      if (time) {
                        scheduleData.horario = time;
                      }
                      if (tipo) {
                        scheduleData.tipoAtendimento = tipo;
                      }
                      this.setState({ scheduleData });
                    }}
                    location={this.props.location}
                    type={this.state?.scheduleData?.tipoAtendimento}
                    getData={() => { this.props.getData() }}
                  />
                )
                return (
                  <View style={{ marginBottom: 10 }}>
                    <Card>
                      <CardContent>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <View style={{ width: "75%", marginTop: 25 }}>
                              {item?.urlFoto ? (
                                <img
                                  alt={item.nome}
                                  src={
                                    item?.urlFoto ??
                                    require("../../../assets/empty_image.png")
                                  }
                                  style={{
                                    flex: 1,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : undefined}
                            </View>
                            <View
                              style={{ paddingTop: 15, alignItems: "center" }}
                            >
                              <InputLabel
                                style={{
                                  fontSize: 22,
                                  color: Colors.black,
                                  fontWeight: "bold",
                                  marginBottom: 10,
                                }}
                              >
                                R$
                                {(this.state?.scheduleData?.tipoAtendimento == "video"
                                  ? item?.procedimentoPadraoVideo?.valor ?? "50.00"
                                  : item?.procedimentoPadrao?.valor ??
                                  "50.00" ?? "0.00")?.replace('.', ',')}
                              </InputLabel>
                              <InputLabel
                                style={{
                                  fontSize: 16,
                                  color: Colors.black,
                                  fontWeight: "bold",
                                }}
                              >
                                Por hora
                              </InputLabel>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                marginVertical: 20,
                              }}
                            >
                              {[...Array(Number.parseInt(3))].map((i1, k1) => {
                                //  console.log(k1);
                                return (
                                  <View>
                                    <Star color={Colors.primary} size={26} />
                                  </View>
                                );
                              })}
                            </View>
                            <View style={{ marginTop: 15, bottom: 25 }}>
                              <Link
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname: `/doutor`,
                                  state: item,
                                }}
                              >
                                <Button
                                  color={Colors.secondary}
                                  variant={"contained"}
                                  style={{ marginTop: 15 }}
                                >
                                  Ver perfil completo
                                </Button>
                              </Link>
                            </View>
                          </View>
                          <View
                            style={{
                              marginStart: 36,
                              marginBottom: 10,
                              flex: 1,
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <View>
                              <View
                                style={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                              >
                                <TouchableOpacity
                                  style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flexDirection: "row",
                                  }}
                                  onPress={async () => {
                                    let session =
                                      localStorage.getItem("user_info");
                                    session = JSON.parse(session);
                                    console.log('FAV IDATA: ', item);
                                    let req = await DeleteHandler(
                                      `pacientes/${session?.id}/favoritos/${item.idFavorito}`,
                                      {
                                        perfilId: item?.perfilId,
                                      }
                                    );
                                    console.log("DEL:: ", req);
                                    if (req?.data?.success) {
                                      await this.props.getData();
                                    }
                                  }}
                                >
                                  <View>
                                    {/*console.log('DOUTOR: ', this.props.data?.favoritoPaciente)*/}
                                  </View>
                                  <View>
                                    {item?.favoritoPaciente == true ? (
                                      <BookmarkBorderOutlined
                                        size={22}
                                        style={{
                                          color: Colors.secondary,
                                          marginTop: 15,
                                          marginEnd: 10,
                                        }}
                                      />
                                    ) : (
                                      <BookmarkSharp
                                        size={22}
                                        style={{
                                          color: Colors.secondary,
                                          marginTop: 15,
                                          marginEnd: 10,
                                        }}
                                      />
                                    )}
                                  </View>
                                </TouchableOpacity>
                                <View style={{ marginStart: 5 }}>
                                  <InputLabel
                                    style={{
                                      fontSize: 18,
                                      marginBottom: 10,
                                      marginTop: 25,
                                    }}
                                  >
                                    {item?.nome}
                                  </InputLabel>
                                  <View></View>
                                </View>
                              </View>
                              <InputLabel
                                style={{ fontSize: 14, marginBottom: 10 }}
                              >
                                {item?.conselho?.codigo
                                  ? item?.conselho?.codigo + ":"
                                  : ""}{" "}
                                {item?.conselho?.codCBO ?? ""}
                              </InputLabel>

                              <InputLabel
                                style={{
                                  marginBottom: 10,
                                  fontSize: 16,
                                  color: Colors.black,
                                  fontWeight: "bold",
                                }}
                              >
                                Sobre mim:
                              </InputLabel>
                              <InputLabel
                                style={{
                                  lineHeight: "1.5",
                                  fontSize: 14,
                                  color: Colors.black,
                                }}
                              >
                                {item?.sobre}
                              </InputLabel>
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "flex-between",
                                marginTop: 15,
                              }}
                            >
                              <View style={{ marginBottom: 15, marginEnd: 25 }}>
                                <InputLabel
                                  style={{
                                    color: Colors.secondary,
                                    fontSize: 18,
                                    lineHeight: "1.5",
                                  }}
                                >
                                  Tempo de Consulta
                                </InputLabel>
                                <InputLabel style={{ lineHeight: "1.5" }}>
                                  <span style={{ color: Colors.secondary }}>
                                    {" "}
                                    •{" "}
                                  </span>
                                  {this.state?.scheduleData?.tipoAtendimento == "video" ? item?.procedimentoPadraoVideo?.duracao ?? 50 : item?.procedimentoPadrao?.duracao ?? 50} minutos
                                </InputLabel>
                              </View>
                              <View style={{ marginBottom: 15, marginEnd: 25 }}>
                                <InputLabel
                                  style={{
                                    color: Colors.secondary,
                                    fontSize: 18,
                                    lineHeight: "1.5",
                                  }}
                                >
                                  Formação Acadêmica
                                </InputLabel>
                                {(item?.formacoes ?? []).map((form, ind) => {
                                  return (
                                    <View key={`formmacao-${ind}`}>
                                      <InputLabel style={{ lineHeight: "1.5" }}>
                                        {" "}
                                        <span
                                          style={{ color: Colors.secondary }}
                                        >
                                          {" "}
                                          •{" "}
                                        </span>{" "}
                                        {form?.nomeFormacao}{" "}
                                      </InputLabel>
                                      <InputLabel style={{ lineHeight: "1.5" }}>
                                        {" "}
                                        <span
                                          style={{ color: Colors.secondary }}
                                        >
                                          {" "}
                                          •{" "}
                                        </span>{" "}
                                        Periodo:{" "}
                                        {form?.periodoDe
                                          ? `(${moment(
                                            form?.periodoDe ?? undefined
                                          ).format("MM/yyyy")} - ${moment(
                                            form?.periodoAte ?? undefined
                                          ).format("MM/yyyy")})`
                                          : "Não Informado"}
                                      </InputLabel>
                                      <InputLabel style={{ lineHeight: "1.5" }}>
                                        {" "}
                                        <span
                                          style={{ color: Colors.secondary }}
                                        >
                                          {" "}
                                          •{" "}
                                        </span>{" "}
                                        {form?.instituicaoEnsino}
                                      </InputLabel>
                                    </View>
                                  );
                                })}
                              </View>

                              <View style={{ marginBottom: 15, marginEnd: 25 }}>
                                <InputLabel
                                  style={{
                                    color: Colors.secondary,
                                    fontSize: 18,
                                    lineHeight: "1.5",
                                  }}
                                >
                                  Atende
                                </InputLabel>
                                {(item?.gruposAtendimento ?? []).map(
                                  (at, ind) => {
                                    return (
                                      <InputLabel
                                        style={{ lineHeight: "1.5" }}
                                        key={`ga-${ind}`}
                                      >
                                        {" "}
                                        <span
                                          style={{ color: Colors.secondary }}
                                        >
                                          {" "}
                                          •{" "}
                                        </span>{" "}
                                        {at.nome}
                                      </InputLabel>
                                    );
                                  }
                                )}
                              </View>

                              <View style={{ marginBottom: 15, marginEnd: 25 }}>
                                <InputLabel
                                  style={{
                                    color: Colors.secondary,
                                    fontSize: 18,
                                    lineHeight: "1.5",
                                  }}
                                >
                                  Idiomas
                                </InputLabel>
                                {(item?.idiomas ?? []).map((at, ind) => {
                                  return (
                                    <InputLabel
                                      style={{ lineHeight: "1.5" }}
                                      key={`ga-${ind}`}
                                    >
                                      {" "}
                                      <span style={{ color: Colors.secondary }}>
                                        {" "}
                                        •{" "}
                                      </span>{" "}
                                      {at.nome}
                                    </InputLabel>
                                  );
                                })}
                              </View>
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: 260,
                                marginTop: 15,
                                bottom: 5,
                              }}
                            >
                              <View>
                                <InputLabel style={{ color: Colors.black }}>
                                  {dateData.years ?? 0} Anos,{" "}
                                  {dateData.months ?? 0} Meses
                                </InputLabel>
                              </View>
                              <View>
                                <InputLabel style={{ color: Colors.black }}>
                                  {item.totalConsultasAtendidas ?? 0} Consultas
                                </InputLabel>
                              </View>
                            </View>
                          </View>
                          <View style={{ alignItems: "flex-end" }}>
                            <View style={{ flex: 1, width: "75%" }}>
                              {console.log("ATENDIMENTO: ", item)}
                              <CalendarioCompleto
                                dados={item}
                                sendSchedule={async (data = {}) => {
                                  this.sendSchedule({ ...item, ...data });
                                }}
                                setDate={(data) => {
                                  const scheduleData = this.state.scheduleData;
                                  scheduleData.data = data.format("yyyy-MM-DD");
                                  this.setState({ scheduleData });
                                }}
                                setTime={({ time, tipo }) => {
                                  const scheduleData = this.state.scheduleData;
                                  if (time) {
                                    scheduleData.horario = time;
                                  }
                                  if (tipo) {
                                    scheduleData.tipoAtendimento = tipo;
                                  }
                                  console.log(scheduleData);
                                  this.setState({ scheduleData });
                                }}
                                location={this.props.location}
                                type={"online"}
                              />
                            </View>
                          </View>
                        </View>
                      </CardContent>
                    </Card>
                  </View>
                );
              })}
            </ScrollView>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                padding: 5,
                margin: 5,
              }}
            >
              <View>
                {/*this.state.pageOptions?.page <
                  this.state.pageOptions.totalPages && (
                    <Button
                      mode={"InputLabel"}
                      onClick={() => {
                        console.log(this.state.pageOptions);
                        this.getData(this.state.pageOptions);
                      }}
                    >
                      Ver Mais
                    </Button>
                    )*/}
              </View>
            </View>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              padding: 5,
              margin: 5,
            }}
          >
            {!this.state?.refreshing ? (
              <View>
                <InputLabel
                  style={{
                    fontSize: 14,
                    color: Colors.black,
                  }}
                >
                  Nenhum Dr(a). favorito adicionado
                </InputLabel>
                <a
                  href="/"
                  style={{
                    marginTop: 10,
                    textDecoration: "none",
                    color: Colors.tertiary,
                  }}
                >
                  Voltar para a busca
                </a>
              </View>
            ) : undefined}
          </View>
        )}
      </View>
    );
  }
}
const mapStateToProps = (store) => ({
  session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSnackbar, setProfileDialog, setTimer }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);

const ItemList = (props) => {
  let item = props.item;
  const [tipo, setTipo] = React.useState("video");

  const today = moment();
  const created = moment(item.dataCad ?? undefined);

  const dateData = {
    years: Math.abs(
      parseInt(today.format("yyyy")) - parseInt(created.format("yyyy"))
    ),
    months: Math.abs(
      parseInt(today.format("MM")) - parseInt(created.format("MM"))
    ),
    days: Math.abs(
      parseInt(moment().format("DD")) -
      parseInt(
        moment(moment().subtract(1, "month")).endOf("month").format("MM")
      )
    ),
  };

  return (
    <View style={{ marginBottom: 10 }}>
      <Card>
        <CardContent>
          <View
            style={{
              flexDirection: window.innerWidth <= 680 ? undefined : "row",
              justifyContent: window.innerWidth <= 680 ? "center" : "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <View style={{ width: "75%", marginTop: 25 }}>
                {item?.urlFoto ? (
                  <img
                    alt={item.nome}
                    src={
                      item?.urlFoto ??
                      require("../../../assets/empty_image.png")
                    }
                    style={{
                      flex: 1,
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  />
                ) : undefined}
              </View>
              <View style={{ paddingTop: 15, alignItems: "center" }}>
                <InputLabel
                  style={{
                    fontSize: 22,
                    color: Colors.black,
                    fontWeight: "bold",
                    marginBottom: 10,
                  }}
                >
                  R$
                  {(tipo == "video"
                    ? item?.procedimentoPadraoVideo?.valor ?? "50.00"
                    : item?.procedimentoPadrao?.valor ?? "50.00" ?? "0.00"
                  )?.replace(".", ",")}
                </InputLabel>
                <InputLabel
                  style={{
                    fontSize: 16,
                    color: Colors.black,
                    fontWeight: "bold",
                  }}
                >
                   Por seção
                </InputLabel>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 20,
                }}
              >
                {[...Array(Number.parseInt(3))].map((i1, k1) => {
                  //  console.log(k1);
                  return (
                    <View>
                      <Star color={Colors.primary} size={26} />
                    </View>
                  );
                })}
              </View>
              <View style={{ marginTop: 10, bottom: 25 }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname: `/doutor`,
                    state: {
                      ...item,
                      tipoAtendimento: tipo,
                    },
                  }}
                >
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    style={{ marginTop: 15 }}
                  >
                    Ver perfil completo
                  </Button>
                </Link>
              </View>
            </View>
            <View
              style={{
                marginStart: 36,
                marginBottom: 10,
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <View>
                <View
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                    onPress={async () => {
                      let session = localStorage.getItem("user_info");
                      session = JSON.parse(session);
                      console.log(item);
                      let req = undefined;
                      //if (item?.favoritoPaciente == true) {
                      req = await DeleteHandler(
                        `pacientes/${session?.id}/favoritos/${item.idFavorito}`,
                        {
                          perfilId: session?.perfilId,
                        }
                      );
                      console.log('DELLL: ', req)
                      /*} else {
                        if (session?.id == undefined) {
                          this.props.sendSchedule({
                            ...item,
                            favorito: true,
                          });
                          return;
                        }
                        req = await PostHandler(
                          `pacientes/${session?.id}/favoritos?perfilId=${session?.perfilId}`,
                          {
                            idDoutor: item?.id ?? item?.doutorId,
                          }
                        );
                      }*/
                      if (req?.data?.success) {
                        props.getData();
                      }
                    }}
                  >
                    <View>
                      {/*console.log('DOUTOR: ', this.props.data?.favoritoPaciente)*/}
                    </View>
                    <View>
                      {item?.favoritoPaciente != false ? (
                        <BookmarkBorderOutlined
                          size={22}
                          style={{
                            color: Colors.secondary,
                            marginTop: 15,
                            marginEnd: 10,
                          }}
                        />
                      ) : (
                        <BookmarkSharp
                          size={22}
                          style={{
                            color: Colors.secondary,
                            marginTop: 15,
                            marginEnd: 10,
                          }}
                        />
                      )}
                    </View>
                  </TouchableOpacity>
                  <View>
                    <InputLabel
                      style={{
                        paddingStart: 10,
                        fontSize: 18,
                        marginBottom: 10,
                        marginTop: 25,
                      }}
                    >
                      {item?.nome}
                    </InputLabel>
                  </View>
                </View>

                <InputLabel style={{ fontSize: 14, marginBottom: 10 }}>
                  {item?.conselho?.codigo ? item?.conselho?.codigo + ":" : ""}{" "}
                  {item?.conselho?.codCBO ?? ""}
                </InputLabel>

                <InputLabel
                  style={{
                    marginBottom: 10,
                    fontSize: 16,
                    color: Colors.black,
                    fontWeight: "bold",
                  }}
                >
                  Sobre mim:
                </InputLabel>

                {item?.sobre?.split("\n")?.map((sobre) => {
                  if (sobre.length < 5) {
                    return;
                  }
                  return (
                    <View>
                      <br />
                      <InputLabel
                        style={{
                          lineHeight: "1.5",
                          fontSize: 14,
                          color: Colors.black,
                        }}
                      >
                        {sobre}
                      </InputLabel>
                    </View>
                  );
                })}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "flex-between",
                  marginTop: 15,
                }}
              >
                <View style={{ marginBottom: 15, marginEnd: 25 }}>
                  <InputLabel
                    style={{
                      color: Colors.secondary,
                      fontSize: 18,
                      lineHeight: "1.5",
                    }}
                  >
                    Tempo de Consulta
                  </InputLabel>
                  <InputLabel style={{ lineHeight: "1.5" }}>
                    {" "}
                    <span style={{ color: Colors.secondary }}> • </span>{" "}
                    {tipo == "video"
                      ? item?.procedimentoPadraoVideo?.duracao ?? 50
                      : item?.procedimentoPadrao?.duracao ?? 50}{" "}
                    minutos
                  </InputLabel>
                </View>
                <View style={{ marginBottom: 15, marginEnd: 25 }}>
                  <InputLabel
                    style={{
                      color: Colors.secondary,
                      fontSize: 18,
                      lineHeight: "1.5",
                    }}
                  >
                    Formação Acadêmica
                  </InputLabel>
                  {(item?.formacoes ?? []).map((form, ind) => {
                    return (
                      <View key={`formmacao-${ind}`}>
                        <InputLabel style={{ lineHeight: "1.5" }}>
                          {" "}
                          <span style={{ color: Colors.secondary }}>
                            {" "}
                            •{" "}
                          </span>{" "}
                          {form?.nomeFormacao}{" "}
                        </InputLabel>
                        <InputLabel style={{ lineHeight: "1.5" }}>
                          {" "}
                          <span style={{ color: Colors.secondary }}>
                            {" "}
                            •{" "}
                          </span>{" "}
                          Periodo:{" "}
                          {form?.periodoDe
                            ? `(${moment(form?.periodoDe ?? undefined).format(
                              "MM/yyyy"
                            )} - ${moment(
                              form?.periodoAte ?? undefined
                            ).format("MM/yyyy")})`
                            : "Não Informado"}
                        </InputLabel>
                        <InputLabel style={{ lineHeight: "1.5" }}>
                          {" "}
                          <span style={{ color: Colors.secondary }}>
                            {" "}
                            •{" "}
                          </span>{" "}
                          {form?.instituicaoEnsino}
                        </InputLabel>
                      </View>
                    );
                  })}
                </View>

                <View style={{ marginBottom: 15, marginEnd: 25 }}>
                  <InputLabel
                    style={{
                      color: Colors.secondary,
                      fontSize: 18,
                      lineHeight: "1.5",
                    }}
                  >
                    Atende
                  </InputLabel>
                  {(item?.gruposAtendimento ?? []).map((at, ind) => {
                    return (
                      <InputLabel
                        style={{ lineHeight: "1.5" }}
                        key={`ga-${ind}`}
                      >
                        {" "}
                        <span style={{ color: Colors.secondary }}>
                          {" "}
                          •{" "}
                        </span>{" "}
                        {at.nome}
                      </InputLabel>
                    );
                  })}
                </View>

                <View style={{ marginBottom: 15, marginEnd: 25 }}>
                  <InputLabel
                    style={{
                      color: Colors.secondary,
                      fontSize: 18,
                      lineHeight: "1.5",
                    }}
                  >
                    Idiomas
                  </InputLabel>
                  {(item?.idiomas ?? []).map((at, ind) => {
                    return (
                      <InputLabel
                        style={{ lineHeight: "1.5" }}
                        key={`ga-${ind}`}
                      >
                        {" "}
                        <span style={{ color: Colors.secondary }}>
                          {" "}
                          •{" "}
                        </span>{" "}
                        {at.nome}
                      </InputLabel>
                    );
                  })}
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: 260,
                  marginTop: 15,
                  bottom: 5,
                }}
              >
                <View>
                  <InputLabel style={{ color: Colors.black }}>
                    {dateData.years ?? 0} Anos, {dateData.months ?? 0} Meses
                  </InputLabel>
                </View>
                <View>
                  <InputLabel style={{ color: Colors.black }}>
                    {item.totalConsultasAtendidas ?? 0} Consultas
                  </InputLabel>
                </View>
              </View>
            </View>
            <View style={{ alignItems: window.innerWidth <= 680 ? "center" : "flex-end" }}>
              <View style={{ flex: 1, width: "75%" }}>
                <CalendarioCompleto
                  dados={item}
                  sendSchedule={async (data = {}) => {
                    this.sendSchedule({ ...item, ...data });
                  }}
                  setDate={props.setDate}
                  setTime={(data) => {
                    props?.setTime(data);
                    setTipo(data.tipo);
                  }}
                  location={props.location}
                  type={tipo}
                />
              </View>
            </View>
          </View>
        </CardContent>
      </Card>
    </View>
  );
};

const styles = {
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
};
